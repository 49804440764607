import type { ProductVariant } from '@commerce/types/product'
import { useEffect, useState } from 'react'

interface referralExclusiveCodeProps {
  variant?: ProductVariant
  metafields: any
}

interface CollectionReferralExclusiveCodeProps {
  code?: string
  discountValue?: string
  value_type?: string
  fixed_amount_sort_key?: number
  value?: string
  utm_campaign?: string
}

const setItemWithExpiry = (
  key: string,
  value: CollectionReferralExclusiveCodeProps,
  timestamp: number
) => {
  const now = new Date()
  const item = {
    value,
    expiry: now.getTime() + timestamp, // 设置过期时间
    timestamp,
  }
  localStorage?.setItem(key, JSON.stringify(item))
}

// 设置referral专属code
const setReferralExclusiveCodeTag = (
  value: CollectionReferralExclusiveCodeProps,
  metafields: any
) => {
  setItemWithExpiry(
    `${metafields?.globalCountry?.code?.toLocaleUpperCase()}-collection-referral-exclusive-code`,
    value,
    30 * 24 * 60 * 60 * 1000
  )
}

const getItemWithExpiry = (key: string) => {
  const itemString = localStorage?.getItem(key)
  if (!itemString) {
    return null
  }
  const item = JSON.parse(itemString)
  const now = new Date()

  // 检查是否过期
  if (now.getTime() > item.expiry) {
    localStorage?.removeItem(key)
    return null
  }
  return item
}

const getReferralExclusiveCode = (metafields: any) => {
  const { listingReferralCode, globalCountry } = metafields
  const query = location.search
  const params = new URLSearchParams(query)
  const queryUtmCampaign = params.get('utm_campaign')
  if (queryUtmCampaign) {
    const referralExclusiveCode = getItemWithExpiry(
      `${globalCountry?.code?.toLocaleUpperCase()}-collection-referral-exclusive-code`
    )
    if (listingReferralCode) {
      if (listingReferralCode?.utm_campaign === queryUtmCampaign) {
        return listingReferralCode
      } else if (referralExclusiveCode && referralExclusiveCode?.value) {
        if (referralExclusiveCode?.value?.utm_campaign === queryUtmCampaign) {
          return referralExclusiveCode?.value
        }
      }
    } else if (referralExclusiveCode && referralExclusiveCode?.value) {
      if (referralExclusiveCode?.value?.utm_campaign === queryUtmCampaign) {
        return referralExclusiveCode?.value
      }
    }
  }
}

function useReferralExclusiveCode({
  variant,
  metafields,
}: referralExclusiveCodeProps) {
  const [referralExclusiveCode, setReferralExclusiveCode] = useState<{
    value: string
    value_type: string
    fixed_amount_sort_key: number
    discountValue: string
    utm_campaign: string
    code: string
  }>({
    value: '',
    value_type: '',
    fixed_amount_sort_key: 0,
    discountValue: '',
    utm_campaign: '',
    code: '',
  })
  const [isSetReferralCode, setIsSetReferralCode] = useState(false)
  const [showReferralExclusiveCode, setShowReferralExclusiveCode] =
    useState(false)

  useEffect(() => {
    const query = location.search
    const params = new URLSearchParams(query)

    // collection页存受邀人专属code逻辑
    if (metafields?.referralExclusiveCode) {
      const {
        code,
        utm_campaign,
        discountValue,
        value_type,
        value,
        fixed_amount_sort_key,
      } = metafields?.referralExclusiveCode
      if (utm_campaign && code && utm_campaign === params.get('utm_campaign')) {
        setReferralExclusiveCodeTag(
          {
            code,
            discountValue,
            value_type,
            fixed_amount_sort_key,
            value,
            utm_campaign,
          },
          metafields
        )
        setShowReferralExclusiveCode(true)
      }
    }
  }, [metafields])

  useEffect(() => {
    const referralExclusiveCode = getReferralExclusiveCode(metafields) || {}
    if (
      !referralExclusiveCode ||
      !Object.keys(referralExclusiveCode).length ||
      !variant
    )
      return
    setReferralExclusiveCode(referralExclusiveCode)
    const productPrice = Number(variant?.price)
    const { value_type, fixed_amount_sort_key } = referralExclusiveCode
    const isSetReferralCode =
      (value_type === 'fixed_amount' &&
        productPrice > Number(fixed_amount_sort_key)) ||
      value_type === 'percentage'
    setIsSetReferralCode(isSetReferralCode)
  }, [metafields, variant?.price, variant])

  return { referralExclusiveCode, isSetReferralCode, showReferralExclusiveCode }
}

export default useReferralExclusiveCode
