import { FC, useState, useMemo } from 'react'
import type { Product } from '@commerce/types/product'
import { Text } from '@components/ui'
import cn from 'classnames'
import { useRouter } from 'next/router'
import { tsHtml2LocaleLink } from '@lib/utils/tools'

interface ProductDescriptionProps {
  product: Product
  metafields: any
  variantMetafields: Record<string, any>
  showFoldBtn?: boolean
}

const ProductDescription: FC<ProductDescriptionProps> = ({
  product,
  metafields,
  variantMetafields,
  showFoldBtn = true,
}) => {
  const { shopCommon } = metafields
  const { presellDesc, presell } = variantMetafields
  const { locale } = useRouter()
  const [fold, setFold] = useState(false)
  const [btnTxt, setBtnTxt] = useState(shopCommon?.see_less)

  const handleFold = (fold: boolean) => {
    setFold(!fold)
    setBtnTxt(fold ? shopCommon?.see_less : shopCommon?.see_more)
  }

  const description = useMemo(() => {
    let html = product?.descriptionHtml
    if (presellDesc && presell && presell === 'presell') {
      html = presellDesc + product?.descriptionHtml
    }
    return tsHtml2LocaleLink({ html, locale })
  }, [product?.descriptionHtml, presellDesc, presell, locale])

  return (
    <>
      <div
        className={cn('relative', {
          'line-clamp-4': fold,
        })}
      >
        <style>
          {`
          .listing-description h1,
          .listing-description h2,
          .listing-description h3,
          .listing-description h4
          .listing-description h5,
          .listing-description h6{
            margin-top: 0 !important;
          }


          .listing-description hr{
            display: none;
          }
          .listing-description ul {
            padding-left: 15px;
            list-style: disc;
          }
          .listing-description * {
            font-size: 15px!important;
            // color: #777!important;
            font-family: inherit!important;
            line-height: 1.2!important;
          }
          .listing-description li {
            margin-bottom: 8px;
          }
          `}
        </style>
        <Text
          className="listing-description relative text-[15px] font-medium text-[#777]"
          html={description}
        ></Text>
        <i
          className={cn(
            'pointer-events-none absolute bottom-0 left-0 h-[96px] w-full opacity-0 min-md:hidden',
            {
              '!opacity-100': fold,
            }
          )}
          style={{
            backgroundImage:
              'linear-gradient(180deg,rgba(255,255,255,0) 0%,white 100%)',
          }}
        ></i>
      </div>
      {showFoldBtn && (
        <div className="mt-2 flex items-center gap-x-6 text-[15px] font-medium min-xl:mt-[9px]">
          <a
            className="flex cursor-pointer items-center gap-x-1 text-[#005D8E]"
            onClick={() => {
              handleFold(fold)
            }}
          >
            <span>{btnTxt}</span>

            <svg
              className={cn({ 'rotate-180': !fold })}
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.54205 6.12494C4.24265 5.82554 3.75722 5.82554 3.45782 6.12494C3.15842 6.42434 3.15842 6.90977 3.45782 7.20917L7.45782 11.2092C7.75722 11.5086 8.24265 11.5086 8.54205 11.2092L12.542 7.20917C12.8415 6.90977 12.8415 6.42434 12.542 6.12494C12.2426 5.82554 11.7572 5.82554 11.4578 6.12494L7.99993 9.58283L4.54205 6.12494Z"
                fill="#005D8E"
              ></path>
            </svg>
          </a>
        </div>
      )}
    </>
  )
}

export default ProductDescription
